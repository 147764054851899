<template>
          <div class="">
            <div class="profile-header profile-bg">
                <div class="row">
                    <div class="col-md-2 text-right pl-5 pr-0 mr-0">
                        <img style="height:100%;width:100%;" :src="getClubLogoImg()" alt="User Avatar" class="mr-3 ">
                    </div>
                    <div class="col-md-6 pl-5">
                        <div class="profiler-detail">
                            <h3>{{ memberInfoData.reg_type_id === 7 ? memberInfoData.campski_name : memberInfoData.full_name }}</h3>
                            <h5>{{ memberInfoData.reg_type_name }} Membership</h5>
                            <div class="b-der"></div>
                            <div class="d-flex">
                                <div class="reg_date mt-3 mr-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                            <img src="/dist/img/profile/ico-registerd-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span class="lable">Registered Date</span>
                                            <span> {{ usdate(memberInfoData.membership_startdate) || "N/A"}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="ex_date mt-3 ml-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                             <img src="/dist/img/profile/ico-expired-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span  class="lable">Expiration Date</span>
                                            <span> {{ memberInfoData.isLifetime != undefined && memberInfoData.isLifetime == 1 ? 'N/A' : usdate(memberInfoData.membership_endtate)}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <div class="row profile-body ">
                <div class="col-md-12">
                    <div class="card mt-3">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Membership History</h3>
                            </div>

                        </div>
                        <!-- /.card-header -->
                        <div class="card-body pt-0 table-responsive ">
                            <b-table
                                id="my-table"
                                :items="allRegistrations"
                                :fields="visibleFields"
                                class="mt-3"
                                hover
                                show-empty
                            >
                                <template
                                    :fields="items"
                                >
                                    <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                            <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                            </button>
                                            <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                        </div>
                                    </div>
                                </template>
                                <template #empty="scope">
                                    <h4>{{ scope.emptyText }}</h4>
                                </template>
                                 <template #cell(waiver)="row">
                                    <div class="text-left py-0 align-middle" v-if="['PAID','Refunded','Chargeback'].includes(row.item.status)">
                                        <div class="btn-group btn-group-sm">
                                            <button v-if="isNotalliancefastpitch" @click="downloadDocs(row.item.id, 'waiver')"
                                                    class="btn btn-success ml-1 mt-0"><i
                                                class="fas fa-download"></i></button>
                                            <button v-if="!isNotalliancefastpitch && (row.item.transaction_id || row.item.transaction_id != 'N/A')" @click="downloadDocs(row.item.id, 'waiver')"
                                                    class="btn btn-success ml-1 mt-0"><i
                                                class="fas fa-download"></i></button>
                                        </div>
                                    </div>
                                </template>

                                <template #cell(receipt)="row">
                                    <div class="text-left py-0 align-middle" v-if="['PAID','Refunded','Chargeback'].includes(row.item.status)">
                                        <div class="btn-group btn-group-sm">
                                            <button v-if="isNotalliancefastpitch" @click="downloadDocs(row.item.id, 'payment')"
                                                    class="btn btn-success ml-1 mt-0"><i
                                                class="fas fa-download"></i></button>
                                            <button v-if="!isNotalliancefastpitch && (row.item.customerprofileid != null || row.item.coupon_id !== null) && row.item.payment" @click="downloadDocs(row.item.id, 'payment')"
                                                  class="btn btn-success ml-1 mt-0"><i
                                              class="fas fa-download"></i></button>
                                        </div>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <!-- /.card-body -->
                    </div>

                    <div class="card mt-3 mb-3">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Donations History</h3>
                            </div>
                        </div>
                        <div class="card-body pt-0 table-responsive">
                            <b-table
                                id="my-table"
                                :items="allDonations"
                                :fields="tblDonationFields"
                                class="mt-3"
                                hover
                                show-empty
                            >
                                <template :fields="items" ></template>
                                <template #empty="scope">
                                    <h4>{{ scope.emptyText }}</h4>
                                </template>
                                <template #cell(sports_discipline)="row" v-if="subdomain=='alliancefastpitch' ">
                                        {{ row.item.type }}
                                </template>
                                <template #cell(sports_discipline)="row" v-else>
                                    {{ listOfDonationSportDiscipline[row.item.sports_discipline] }}
                                </template>
                                <template #cell(cost)="row">
                                    $ {{ row.item.cost }}
                                </template>
                                <template #cell(receipt)="row">
                                    <div class="text-left py-0 align-middle" v-show="row.item.type=='Donation'">
                                        <div class="btn-group btn-group-sm">
                                            <button @click="downloadDonarionDocs(row.item.id)"
                                                    class="btn btn-success ml-1 mt-0"><i
                                                class="fas fa-download"></i></button>
                                        </div>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <!-- /.card-body -->
                    </div>


                  <div class="row profile-body mb-3" v-if="getSiteProps('general.merchandise')">
                    <div class="col-md-12 pr-0 pl-0">
                      <div class="card table-head Merchandise mb-3">
                        <div class="card-header">
                          <div class="header-title">
                            <h3>Merchandise</h3>
                          </div>
                        </div>
                        <div class="card-body pt-0 table-responsive">
                            <b-table
                              v-if="subdomain == 'waterski'"
                              id="myMerchandiseTable"
                              :items="allmerchandise"
                              :fields="merchandiseTbl"
                              class="mt-3"
                              hover
                              show-empty
                          >
                            <template #cell(price)="row">
                              $ {{ row.item.price }}
                            </template>
                            <template #cell(total)="row">
                              $ {{ row.item.total }}
                            </template>
                            <template #cell(registration.status)="row">
                              {{row.transaction_code!='' ? 'Paid' : 'Not Paid'}}
                            </template>
                            <template #cell(registration.merchandise_order.fulfilled)="row">
                                {{row.item.registration.merchandise_order.fulfilled !='0' ? 'Yes' : 'No'}}
                            </template>
                            <template #cell(registration.merchandise_order.fulfilled_date)="row">
                              {{row.item.registration.merchandise_order.fulfilled_date == null ? 'N/A' : row.item.registration.merchandise_order.fulfilled_date}}
                            </template>
                            <template #cell(registration.merchandise_order.tracking_id)="row">
                              {{row.item.registration.merchandise_order.tracking_id == null ? 'N/A' : row.item.registration.merchandise_order.tracking_id}}
                            </template>
                            </b-table>
                            <b-table
                            v-if="subdomain == 'shooting'"
                            id="myMerchandiseTable"
                            :items="allmerchandise"
                            :fields="merchandiseTbls"
                            class="mt-3"
                            hover
                            show-empty
                        >
                          <template #cell(price)="row">
                            $ {{ row.item.price }}
                          </template>
                          <template #cell(total)="row">
                            $ {{ row.item.total }}
                          </template>
                          <template #cell(registration.status)="row">
                            {{row.transaction_code!='' ? 'Paid' : 'Not Paid'}}
                          </template>
                          <template #cell(registration.merchandise_order.fulfilled)="row">
                              {{row.item.registration.merchandise_order.fulfilled !='0' ? 'Yes' : 'No'}}
                          </template>
                          <template #cell(registration.merchandise_order.fulfilled_date)="row">
                            {{row.item.registration.merchandise_order.fulfilled_date == null ? 'N/A' : row.item.registration.merchandise_order.fulfilled_date}}
                          </template>
                          <template #cell(registration.merchandise_order.tracking_id)="row">
                            {{row.item.registration.merchandise_order.tracking_id == null ? 'N/A' : row.item.registration.merchandise_order.tracking_id}}
                          </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row profile-body" v-if="subdomain == 'waterski'&&memberInfoData.reg_type_id!=7">
                 <div class="col-md-12">
                   <div class="card table-head Merchandise mb-4">
                     <div class="card-header">
                       <div class="header-title">
                         <h3>Groups</h3>
                       </div>
                     </div>
                     <div class="card-body pt-0 table-responsive">
                       <b-table
                           id="myMerchandiseTable"
                           :items="allcommittees"
                           :fields="committeeTbl"
                           class="mt-3"
                           hover
                           show-empty
                       >
                         <template #cell(price)="row">
                           $ {{ row.item.price }}
                         </template>
                         <template #cell(color)="row">
                           {{ row.item.variant_name.split('|')[0] }}
                         </template>
                         <template #cell(size)="row">
                           {{ row.item.variant_name.split('|')[1] }}
                         </template>
                         <template #cell(total)="row">
                           $ {{ row.item.total }}
                         </template>
                         <template #cell(registration.status)="row">
                           {{row.transaction_code!='' ? 'Paid' : 'Not Paid'}}
                         </template>
                         <template #cell(actions)="row">
                           <div class="text-left py-0 align-middle">
                             <div class="btn-group btn-group-sm">
                               <!-- <button
                                   class="btn btn-primary"
                                   @click="viewRow(row.item, row.index, $event.target)"
                               >
                                 <i class="fas fa-eye"></i>
                               </button>
                               <button
                                   class="btn btn-primary ml-1"
                                   @click="editRow(row.item, $event.target)"
                               >
                                 <i class="fas fa-pencil-alt"></i>
                               </button> -->
                               <button
                                   class="btn btn-danger ml-1"
                                   @click="deleteRow(row.item.id)"
                               >
                                 <i class="fas fa-trash"></i>
                               </button>
                             </div>
                           </div>
                         </template>
                       </b-table>
                     </div>
                   </div>
                 </div>
               </div>

                    <!-- <div class="dashboard-qustion pt-0 ">
                           <p class="footer-quest pb-3" v-html="this.getSiteProps('footertop.question')"></p>
                    </div> -->
                </div>
            </div>
            <!-- Purchase History -->
            <div class="row profile-body" id="purchaseHistorySection" v-if="this.getSiteProps('general.purchaseHistory')">
                <div class="col-md-12">
                <div class="card mt-3">
                    <div class="card-header">
                        <div class="header-title">
                            <h3>Purchase History</h3>
                        </div>
                    </div>
                    
                    <div class="card-body mt-4 pt-0">
                        <div class="d-flex">
                        <div class="col-md-3">
                            <b-form inline>
                                <label class="mr-sm-2 font-weight-normal">Show</label>
                                <b-form-select
                                id="inline-form-custom-select-pref"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="purchaseHistory.perPage"
                                :options="purchaseHistory.viewEntryOptions"
                                size="md"
                                ></b-form-select
                                >entries
                            </b-form>
                            </div>
                            <div class="col-md-6 d-inline-flex">
                                <div class="form-inline mr-1">
                                    <label class="form-label mr-2">From Date</label>
                                    <div class="form-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick
                                                v-model="purchaseHistory.filter.startDate"
                                                :format="'MM/DD/YYYY'"
                                                :displayFormat="'MM/DD/YYYY'"
                                                placeholder="start"
                                                v-mask="'##/##/####'"
                                                :parseDate="parseDatePick"
                                            ></date-pick>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-inline">
                                    <label class="form-label mr-2">To Date</label>
                                    <div class="form-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick
                                                v-model="purchaseHistory.filter.endDate"
                                                :format="'MM/DD/YYYY'"
                                                :displayFormat="'MM/DD/YYYY'"
                                                placeholder="End"
                                                v-mask="'##/##/####'"
                                                :parseDate="parseDatePick"
                                            ></date-pick>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-3">
                            <b-input-group size="md">
                                <b-form-input
                                class="border border-secondary"
                                id="filter-input"
                                v-model="purchaseHistory.filter.search"
                                type="search"
                                placeholder="Search"
                                ></b-form-input>
                            </b-input-group>
                        </div>
                    </div>
                    <b-table
                        id="purchaseHistoryTable"
                        :per-page="purchaseHistory.perPage"
                        :current-page="purchaseHistory.currentPage"
                        :items="purchaseHistory.data"
                        :fields="purchaseHistoryTbl"
                        class="mt-3"
                        :busy="!purchaseHistory.loaded"
                        :filter="purchaseHistory.filter"
                        :filter-included-fields="purchaseHistory.filterOn"
                        :filter-function="customFilter"
                        responsive='true'
                        @filtered="onFilteredPurchaseHistory"
                        hover
                        show-empty
                    >
                    <template #cell(receipt)="row">
                        <div class="text-left py-0 align-middle">
                            <div class="btn-group btn-group-sm" v-if="row.item.transaction_type == 'donation'">
                                <button @click="downloadDonarionDocs(row.item.donation.id)"
                                        class="btn btn-success ml-1 mt-0"><i
                                    class="fas fa-download"></i></button>
                            </div>
                            <div class="btn-group btn-group-sm" v-else-if="row.item.transaction_type == 'registraion' && !row.item.transaction_type.includes('Discount Coupon')">
                                <button @click="downloadDocs(row.item.ref_regid, 'payment')"
                                        class="btn btn-success ml-1 mt-0"><i
                                    class="fas fa-download"></i></button>
                            </div>
                            <div class="btn-group btn-group-sm" v-else-if="['mvr','background_screening'].includes(row.item.transaction_type)">
                                <button @click="downloadBgScreeningDocs(row.item.payment.id, row.item.product)"
                                        class="btn btn-success ml-1 mt-0"><i
                                    class="fas fa-download"></i></button>
                            </div>
                          <div class="btn-group btn-group-sm" v-else-if="row.item.transaction_type=='merchandise'">
                            <button @click="downloadOtherDocs(row.item.ref_regid,row.item.ref_memid, row.item.transaction_type, row.item.transaction_id)"
                                    class="btn btn-success ml-1 mt-0"><i
                                class="fas fa-download"></i></button>
                          </div>
                        </div>
                    </template>
                    </b-table>
                    <br />
                    <b-pagination
                        v-if="purchaseHistory.loaded"
                        v-model="purchaseHistory.currentPage"
                        :total-rows="purchaseHistoryRows"
                        :per-page="purchaseHistory.perPage"
                        prev-text="Previous"
                        next-text="Next"
                        aria-controls="purchaseHistoryTable"
                        class="float-right custom-color"
                        hide-goto-end-buttons
                    ></b-pagination>
                    </div>
                </div>
                </div>
            </div>
        </div>
</template>

<script>
import moment from "moment";
import axios from 'axios';


export default {
    name: "myMembership",
    components: {},
    data() {
        return {
            memberInfoData:"",
            prop_footer: {},
            tblFields: [
                // { key: 'member_id', sortable: true, label: 'Member #' },
                { key: 'registered_as', sortable: true, label: 'Registration Type' },
                { key: 'transaction_id', sortable: true, label: 'Transaction Id' },
                { key: 'transaction_date', sortable: true, label: 'Transaction Date',formatter: 'formatDatenew' },
                { key: 'start_date', sortable: true, label: 'Effective From'},
                { key: 'expires', sortable: true, label: 'Effective To', formatter: (value, key, item) => {
                    return item.membership_period != 999 ? value : "-";
                }},
                { key: 'status', sortable: true, label: 'Status'},
                { key: 'waiver', sortable: false, label: 'Waiver' },
                { key: 'receipt', sortable: false, label: 'Receipt'},
            ],
            allRegistrations:"",
            allDonations:"",
            tblDonationFields:[
                { key: 'sports_discipline', sortable: true, label: 'Sports Discipline' },
                { key: 'cost', sortable: true, label: 'Donation Amount (USD)' },
                { key: 'transaction_code', sortable: true, label: 'Transaction Id' },
                { key: 'transaction_time', sortable: true, label: 'Paid At', formatter: "usdate" },
                { key: 'receipt', sortable: false, label: 'Receipt'},
            ],
          allmerchandise:{},
          allcommittees:{},
          committeeTbl:[
            {key: 'committee_name', sortable: true, label: 'Name', formatter: 'formatName'},
            {key: 'committee.group.name', sortable: true, label: 'Group', formatter: 'formatName'},
            {key: 'position.position', sortable: true, label: 'Position', formatter: 'formatName'},
            {key: 'region', sortable: true, label: 'Region', formatter: 'formatName'},
            {key: 'effective_date', sortable: true, label: 'Effective From', formatter: 'formatDatenew'},
            {key: 'expiration_date', sortable: true, label: 'Expiration Date', formatter: 'formatDatenew'},
            {key: 'committee.safe_sport', sortable: true, label: 'Safe Sport', formatter: 'formatName'},
            {key: "actions", sortable: false}
            ],
          merchandiseTbl:[
            {key: 'name', sortable: true, label: 'Product', formatter: 'formatName'},
            {key: 'price', sortable: true, formatter: 'formatName'},
            {key: 'quantity', sortable: true, formatter: 'formatName'},
            {key: 'total', sortable: true, formatter: 'formatName'},
            {key: 'registration.transaction_id', sortable: true, label: 'Transaction Id'},
            {key: 'registration.transaction_date', sortable: true, label: 'Transaction Date', formatter: 'usdate'},
            {key: 'registration.status', sortable: true, label: 'Status'},
            {key: 'registration.merchandise_order.fulfilled', sortable: true, label: 'Fulfilled'},
            {key: 'registration.merchandise_order.fulfilled_date', sortable: true, label: 'Fulfilled Date'},
            {key: 'registration.merchandise_order.tracking_id', sortable: true, label: 'Tracking ID'}
          ],
          merchandiseTbls:[
            {key: 'name', sortable: true, label: 'Product', formatter: 'formatName'},
            {key: 'price', sortable: true, formatter: 'formatName'},
            {key: 'quantity', sortable: true, formatter: 'formatName'},
            {key: 'total', sortable: true, formatter: 'formatName'},
            {key: 'registration.transaction_id', sortable: true, label: 'Transaction Id'},
            {key: 'registration.transaction_date', sortable: true, label: 'Transaction Date', formatter: 'usdate'},
            {key: 'registration.status', sortable: true, label: 'Status'}
          ],
            purchaseHistoryTbl:[
                {key: 'transaction_date', sortable: true, label: 'Transaction Date',
                    formatter: (value) => {
                    return moment(String(value)).format("MM/DD/YYYY");
                    }
                },
                {key: 'transaction_id', sortable: true, label: 'Transaction ID'},
                {key: 'product', sortable: true, label: 'Product', 
                formatter: (value , key , item) => {
                    let couponValue = value + ' ('+item.name+')';
                    return item.name != null && item.name.includes('Discount Coupon') ? couponValue : value;
                    }
                },
                {key: 'cost', sortable: false, label: 'Cost', 
                    formatter: (value) => {
                    return '$'+parseFloat(value).toFixed(2);
                    }
                },
                 { key: 'receipt', sortable: false, label: 'Receipt'},
                
            ],
            purchaseHistory : {
                data: [],
                totalRows: 1,
                loaded: false,
                perPage: 10,
                currentPage: 1,
                filter: {
                    search: '',
                    startDate: '',
                    endDate: '',
                    // year:moment().year()
                },
                filterOn: [],
                viewEntryOptions: [
                    { value: 5, text: "5" },
                    { value: 10, text: "10" },
                    { value: 20, text: "20" },
                    { value: 50, text: "50" }
                ]
            },
            filterValid: true,
            isNotalliancefastpitch:true
        }
    },
    methods: {
        formatDate(value)
        {
            if(value){
                return moment(value).format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
        deleteRow(id) {
        this.displayAlert = false;
            this.$bvModal.msgBoxConfirm("Are you sure you are removing this member from this committee?" ,{
            title: 'Please Confirm',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            centered: true
            }).then(value => {
            if (String(value) == "true") {
                axios.delete(process.env.VUE_APP_URL + "api/committee_members/delete/" + id)
                .then(function (response) {
                this.alertMessage = "The member has been removed from this committee!";
                this.displayAlert = true;
                this.loadRegistrationHistory();
                //this.resetEditModal();
                }.bind(this))
                .catch(function (error) {
                console.log(error);
                });
            }
            })
            .catch(err => {
            console.log(err);
            });
            return false;
        },

        loadRegistrationHistory() {
            axios.get(this.basePath+"api/member/registration/history",{ headers:this.memberHeaders })
                .then((response)=>{
                    const resp  = response.data.data;
                    this.allRegistrations = resp;
                }).catch((error) =>{
                console.log(error);
            });
            axios.get(this.basePath+"api/member/donations",{ headers:this.memberHeaders })
                .then((response)=>{
                    this.allDonations = response.data.data;
                }).catch((error) =>{
                console.log(error);
            });
            axios.get(this.basePath+"api/member/merchandise",{ headers:this.memberHeaders })
                .then((response)=>{
                    this.allmerchandise = response.data.data;
                }).catch((error) =>{
                console.log(error);
            });
            axios.get(this.basePath+"api/member/committees",{ headers:this.memberHeaders })
                .then((response)=>{
                    this.allcommittees = response.data.data;
                }).catch((error) =>{
                console.log(error);
            });
        },
        downloadDocs(item, type) {
            this.downloadPdf(this.basePath + 'api/member/download/'+type+'/'+item, 'Reg'+item+"_"+type );
        },
        downloadDonarionDocs(item) {
            this.downloadPdf(this.basePath + 'api/member/donation/'+item, 'Donation');
        },
        downloadBgScreeningDocs(paymentId , type){
            this.downloadPdf(this.basePath + 'api/member/bgScreeningInvoice/'+paymentId, type);
        },
      downloadOtherDocs(regid, memid, type, transaction_id){
        this.downloadPdf(this.basePath + 'api/member/receiptDownload/'+type+'/'+regid+'/'+memid+'/'+transaction_id, 'Reg'+regid+"_"+type );
      },
        downloadPdf(url, name){
            axios.get(url, {headers:this.memberHeaders, responseType: 'arraybuffer'})
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/pdf' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = this.memberInfoData.member_id+"_"+name+'.pdf';
                    link.click();
                    link.remove();
                }).catch((error)=>{
                console.log("error download");
            });
        },
        getClubLogoImg(){
            return (this.memberInfoData.profile_pic!=null? process.env.VUE_APP_URL+this.memberInfoData.profile_pic:'/dist/img/profile1.png')+'?v='+moment().format('x');
        },
        loadPurchaseHistory(){
            let memberId = this.memberInfoData.person_id;
            axios.get(this.basePath+"api/member/purchase-history/"+ memberId,{ headers:this.memberHeaders }).then(response => {
                this.purchaseHistory.data = response.data.data;
                this.purchaseHistory.loaded = true;
                this.purchaseHistory.totalRows = this.purchaseHistory.data.length;
            })
            .catch(err => {
                console.log(err);
            })
        },
        onFilteredPurchaseHistory(filteredItems,data) {       
            this.purchaseHistory.totalRows = filteredItems.length;
            this.purchaseHistory.currentPage = 1;
        },
        customFilter(rows, filter){
            let transactionValid = false;
            let searchFlag = false;
            let startDateFlag = false;
            let endDateFlag = false;
            let compareDateFlag = false;

            let filterNames = rows.name + rows.product + rows.transaction_id;
            if(filter.search != null && filter.search.trim() != ''){
                searchFlag = true;
               if( filterNames.toLowerCase().includes(filter.search.trim().toLowerCase())){
                   transactionValid =  true;
               }
            }
            
            if(filter.startDate != null && filter.startDate.trim() != '' && (filter.endDate.trim() == '' ) ){
                const start_date = filter.startDate;
                const filter_start_date = moment(rows.transaction_date).format("MM/DD/YYYY");
               
                if( moment(start_date).isSame(filter_start_date)  ){
                    transactionValid =  true;
                }else if( moment(start_date).isBefore(filter_start_date) ){
                    transactionValid =  true;
                }
                startDateFlag = true;
            }

            if(filter.endDate != null && filter.endDate.trim() != '' && filter.startDate.trim() == ''){
                const end_date = filter.endDate;
                const filter_end_date = moment(rows.transaction_date).format("MM/DD/YYYY");
               
                if( moment(end_date).isSame(filter_end_date)  ){
                    transactionValid =  true;
                }else if( moment(end_date).isAfter(filter_end_date) ){
                    transactionValid =  true;
                }
                endDateFlag = true;
            }

            if(filter.endDate.trim() != '' && filter.startDate.trim() != '' ){
                const filter_transaction_date = moment(rows.transaction_date).format("MM/DD/YYYY");
                const start_date = filter.startDate;
                const end_date = filter.endDate;

                if( moment(start_date).isSame(filter_transaction_date)  ){
                    transactionValid =  true;
                }else if( moment(end_date).isSame(filter_transaction_date)  ){
                    transactionValid =  true;
                }else if( moment(filter_transaction_date).isBetween(start_date, end_date)){
                    transactionValid =  true;
                }
                compareDateFlag = true;
            }
            
            if( searchFlag || startDateFlag || endDateFlag || compareDateFlag){
                return transactionValid;
            }
            return  true;
        }
    },
    computed: {
        purchaseHistoryRows() {
            return this.purchaseHistory.data.length;
        },
        visibleFields(){
          return this.tblFields.filter(item => {
            return (this.memberInfoData.reg_type_id !== 7 || item.key !== "waiver");
          });
        }
    },
    mounted() {
        this.loadRegistrationHistory();
        this.memberInfoData = JSON.parse(localStorage.getItem('member'));
        this.prop_footer = this.getSiteProps('memberRegistration.footer');
        
        if(this.memberInfoData.reg_type_id == 2){
            this.tblFields = this.tblFields.filter(item => item.key != 'waiver')
        }
        this.loadPurchaseHistory();

      if(this.subdomain == 'alliancefastpitch'){
        this.isNotalliancefastpitch=false;
      }
    }
}
</script>
